import { getFunctions, httpsCallable } from 'firebase/functions';
import { devMode } from '../helpers/data';

/**
 * Build upload params for delivery form file attachments
 */
export const buildDeliveryFormFileParams = ({
  firstName,
  lastName,
  docType,
  timestamp,
  file
}: any) => {
  if (!firstName || !lastName || !docType || !timestamp || !file) {
    throw new Error('Missing required parameters.');
  }

  // Build file name
  const fileType = file.type;
  const parts = fileType.split('/');
  const imageType = parts[1];
  let fileExt;

  switch (imageType) {
    case 'png':
      fileExt = '.png';
      break;
    case 'jpeg':
      fileExt = '.jpg';
      break;
    default:
      fileExt = '';
  }

  let name;

  switch (docType) {
    case 'govId':
      name = 'government-id';
      break;
    case 'medCard':
      name = 'medical-card';
      break;
    case 'deliveryCert':
      name = 'delivery-certificate';
      break;
    default:
      name = '';
  }

  const fileName = `${name}${fileExt}`;

  // Build file path
  const path = `Rhode Island/Uploads/${lastName}, ${firstName}/${timestamp}`;

  return {
    path,
    fileName,
    file
  };
};

/**
 * Process subscribe form
 * Sends the user's email to Mailchimp
 */
export const sendSubscribeForm = async ({
  token,
  email,
  location,
  success,
  failure
}: any) => {
  try {
    const functions = getFunctions();
    const processSubscribeFormSubmission = httpsCallable(functions, 'processSubscribeFormSubmissionCallable');
    
    if (devMode) {
      console.log('Submitting form in development mode.');
    }

    // Build params
    const params = {
      token,
      email,
      location,
      devMode,
    };

    // Attempt submission
    await processSubscribeFormSubmission(params);

    // Fire success callback
    success();
  } catch (error) {
    console.log('Failed to process subscribe form data: ', error);
    // TODO: Improve error reporting based on function error code
    // Example: 'functions/unauthenticated' == reCAPTCHA verification failure
    if (failure) { failure() }
  }
};

/**
 * Process quiz data
 * This accomplishes three things:
 * 1. Saves the quiz responses to a Google spreadsheet
 * 2. Sends the user's name and email to Mailchimp
 * 3. Sends the user an email with a coupon + quiz results
 */
export const processQuizData = async ({
  token,
  userData,
  responses,
  results,
  success,
  failure
}: any) => {
  try {
    const functions = getFunctions();
    const processQuizFormSubmission = httpsCallable(functions, 'processQuizFormSubmissionCallable');
    
    if (devMode) {
      console.log('Submitting form in development mode.');
    }

    // Build params
    const params = {
      token,
      userData,
      responses,
      results,
      devMode,
    };

    // Attempt submission
    await processQuizFormSubmission(params);

    // Fire success callback
    success();
  } catch (error) {
    console.log('Failed to process quiz form data: ', error);
    // TODO: Improve error reporting based on function error code
    // Example: 'functions/unauthenticated' == reCAPTCHA verification failure
    if (failure) { failure() }
  }
};

/**
 * Submit delivery form
 */
export const sendDeliveryForm = async ({
  token,
  firstName,
  lastName,
  email,
  phone,
  address,
  govId,
  medCard,
  deliveryCert,
  referralCode,
  success,
  failure
}: any) => {
  try {
    const functions = getFunctions();
    const processRhodeIslandDeliveryFormSubmission = httpsCallable(functions, 'processRhodeIslandDeliveryFormSubmissionCallable');
    
    if (devMode) {
      console.log('Submitting form in development mode.');
    }

    // Build params
    const params = {
      token,
      firstName,
      lastName,
      email,
      phone,
      address,
      govId,
      medCard,
      deliveryCert,
      referralCode,
      devMode,
    };

    // Attempt submission
    await processRhodeIslandDeliveryFormSubmission(params);

    // Fire success callback
    success();
  } catch (error) {
    console.log('Failed to submit delivery form results: ', error);
    // TODO: Improve error reporting based on function error code
    // Example: 'functions/unauthenticated' == reCAPTCHA verification failure
    if (failure) { failure() }
  }
};

/**
 * Submit franchise info request form
 */
export const sendFranchiseInfoRequestForm = async ({
  token,
  firstName,
  lastName,
  email,
  streetAddress,
  city,
  state,
  netWorth,
  availableCash,
  desiredLocation,
  phone,
  availability,
  referral,
  success,
  failure
}: any) => {
  try {
    const functions = getFunctions();
    const processFranchiseInfoRequestFormSubmission = httpsCallable(functions, 'processFranchiseInfoRequestFormSubmissionCallable');
    
    if (devMode) {
      console.log('Submitting form in development mode.');
    }

    // Build params
    const params = {
      token,
      firstName,
      lastName,
      email,
      streetAddress,
      city,
      state,
      netWorth,
      availableCash,
      desiredLocation,
      phone,
      availability,
      referral,
      devMode,
    };

    // Attempt submission
    if (params.netWorth == "") {
      params.netWorth = "N/A";
    }

    await processFranchiseInfoRequestFormSubmission(params);

    // Fire success callback
    success();
  } catch (error) {
    console.log('Failed to submit franchise info request form results: ', error);
    // TODO: Improve error reporting based on function error code
    // Example: 'functions/unauthenticated' == reCAPTCHA verification failure
    if (failure) { failure() }
  }
};
