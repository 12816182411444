import React, { useEffect, useRef, useState } from 'react';

// Import quiz data
import { quizData2 } from '../../../helpers/quiz';

// Import info button images
import InfoInactive from '../../../assets/images/info-inactive.png';
import InfoActive from '../../../assets/images/info-active.png';

// Import Ant Design
import { Tooltip } from 'antd';

const Question = ({
  totalQuestions,
  questionIndex,
  quizResponses,
  updateQuestionIndex,
  updateMultipleChoice,
  updateQuizResponses,
  transition,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const textAreaRef = useRef();

  useEffect(() => {
    // Add handlers upon mount
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize, true);

    // Remove listeners upon unmount
    return (() => {
      window.removeEventListener('resize', handleResize, true);
    });
  }, []);

  // Pull selected answers from quizResults
  const currentAnswers = quizResponses.multipleChoice[questionIndex]?.answers ?? [];

  // Pull current question data
  const currentQuestion = quizData2[questionIndex]; // Could be undefined

  // Build handler for multiple choice buttons
  // Takes an answer object as an argument
  // We are only allowing one choice per question
  const handleAnswerButton = (answer) => {
    if (transition) {
      return;
    }
    const answerArr = [ answer ];
    updateMultipleChoice(questionIndex, answerArr);
    updateQuestionIndex('increment');
  }

  // Build handler for back button
  const handleBackButton = () => {
    updateQuestionIndex('decrement');
  }

  // Build handler for next button
  const handleNextButton = () => {
    if (transition) {
      return;
    }
    updateQuestionIndex('increment');
  }

  // Determine question number
  const questionNumber = questionIndex + 1;

  // Build UI
  const questionText = (currentQuestion?.question ?? "");
  const possibleAnswers = (currentQuestion?.answers ?? []);
  const answerButtons = possibleAnswers.map((answer) => {
    const answerText = answer.text ?? []; // An array of strings
    const selected = currentAnswers.find((choice) => {
      return choice.id === answer.id;
    });
    const buttonClass = `answerButton${selected ? " selected" : ""}${answerText.length > 1 ? " large" : ""}`;

    return (
      <button 
        key={answer.id}
        className={buttonClass}
        onClick={() => handleAnswerButton(answer)}
      >
        {answerText.map((text, index) => (<span key={index} className={index > 0 ? "topPadding" : ""}>{text}</span>))}
      </button>
    );
  });

  // Determine dynamic width of answer button wrapper
  // This is only valid at tablet width and greater (768px)
  let answerButtonWrapperWidth: any = "100%";
  if (windowWidth >= 980) {
    if (possibleAnswers.length <=2) {
      answerButtonWrapperWidth = 400;
    }
  }

  // If a large number of possible answers, display answers in a grid
  const answerButtonWrapperStyle = `answerButtonWrapper${possibleAnswers.length >= 8 ? " grid" : ""}`;

  const infoImage = showInfo ? InfoActive : InfoInactive;
  const infoText = currentQuestion?.info ?? "";

  const currentQuestionId = currentQuestion?.id ?? "questionId";
  const textAreaContent = quizResponses[currentQuestionId] ?? "";

  const questionIndexStyle = `questionIndex${transition ? ' invisible' : ''}`;
  const quizContentStyle = `quizContent${transition ? ' invisible' : ''}`;
  const quizQuestionStyle = `quizQuestion center ${currentQuestionId}`;

  return (
    <section className="Question">
      <div>
        <div className="quizHeading">
          <span className="navControls left">
          { (questionIndex !== 0) && (
            <button
              className="backButton"
              onClick={handleBackButton}
            >
              &lt; Back
            </button>
          )}
          </span>
          <span className={questionIndexStyle}>
            {questionNumber} / {totalQuestions}
          </span>
          <span className="navControls right" />
        </div>
        <div className={quizContentStyle}>
          <p className={quizQuestionStyle}>
            { questionText }
          </p>
          { (currentQuestion?.type === "multipleChoice") && (
            <div 
              className={answerButtonWrapperStyle}
              style={{ width: answerButtonWrapperWidth }}
            >
              { answerButtons }
            </div>
          )}
          { (currentQuestion?.type === "openText") && (
            <div className="answerTextAreaWrapper">
              <textarea 
                id={currentQuestionId}
                ref={textAreaRef}
                name={currentQuestionId}
                placeholder="Type here"
                value={textAreaContent}
                onChange={(event) => updateQuizResponses(currentQuestionId, event.target.value)}
              />
              <div className="nextButtonWrapper">
                <button type="button" className="submitButton" onClick={handleNextButton}>
                  <span>
                    Next question
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="infoButtonWrapper">
        <Tooltip 
          title={infoText}
          trigger="click"
          overlayClassName="infoTooltip"
          color="#00433A"
          onOpenChange={(visible) => setShowInfo(visible)}
        >
          <span className="infoButton">
            <img 
              src={infoImage} 
              alt="Info"
            />
          </span>
        </Tooltip>
      </div>
    </section>
  );
}

export default Question;
