import React from 'react';

// Import React Router
// https://reactrouter.com/
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";

import StoreInfo from '../../shared/StoreInfo';
import StoreMenu from '../../shared/StoreMenu';
import { Helmet } from 'react-helmet';
import { storeInfo } from '../../helpers/data';

export default function Store(props) {
  // Acquire route match data
  const match = useRouteMatch();

  function storeNameFrom(menuID) {
    switch (menuID) {
      case 'ct001':
        return "CT Medical & Recreational";
        break;
      case 'me001':
        return "ME Recreational";
        break;
      case 'md001':
        return "MD Medical & Recreational";
        break;
      case 'nj001':
        return "NJ Medical";
        break;
      case 'ri001':
        return "RI Medical & Recreational";
        break;
      default:
        return "";
    }
  }

  function metaDescriptionFrom(menuID) {
    var state = '';
    switch (menuID) {
      case 'ct001':
        state = "CT";
        break;
      case 'me001':
        state = "ME";
        break;
      case 'md001':
        state = "MD";
        break;
      case 'nj001':
        state = "NJ";
        break;
      case 'ri001':
        state = "RI";
        break;
      default:
        break;
    }
    return `Looking for a weed dispensary in  {{state}} "? Sweetspot is a premium Connecticut dispensary, with a focus on sustainability and top-quality products. ✓ Come see us!`;
  }

  return (
    <Switch>
      {storeInfo.map((info) => {
        const { menus } = info;
        const menuRoutes = [];
        // Add the primary store page
        menuRoutes.push(
          <Route exact path={`${match.url}/${info.id}`}>
            <Helmet>
              <meta charSet="utf-8" />
              <meta name="description" content={metaDescriptionFrom(info.id)} />
              <title>Sweetspot Weed Dispensary: {storeNameFrom(info.id)}</title>
              <link rel="canonical" href={`https://sweetspotfarms.com/store/${info.id}`} />
            </Helmet>

            <StoreInfo info={info} />
          </Route>
        );
        // Add the menu pages
        menus.forEach((menu) => {
          menuRoutes.push(
            <Route key={menu.id} exact path={`${match.url}/${info.id}/menu/${menu.dispensaryType.substring(0, 3)}`}>
              <StoreMenu menuId={menu.id} />
            </Route>
          );
        });
        // Add a general catch all
        menuRoutes.push(
          <Route path={`${match.url}/${info.id}/*`}>
            <Redirect to={`${match.url}/${info.id}`} />
          </Route>
        );
        return menuRoutes;
      })}
      <Route path={`${match.url}`}>
        {/* If any other store-related path is used, re-direct to Maryland location */}
        <Redirect to={`${match.url}/md001`} />
      </Route>
    </Switch>
  );
}
