import React from 'react';
import { useHistory } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { getAnalytics, logEvent } from 'firebase/analytics';

export default function DesktopNav(props: any) {
  const {theme} = props;
  const navClassName = `DesktopNav${theme === 'light' ? ' light' : ''}`;
  const analytics = getAnalytics();

  const history = useHistory();

  const handleMenuButton = (id: any) => {
    const section: any = document.getElementById(id);
    const rect = section.getBoundingClientRect();
    const yDistance = rect.top; // From the top of the viewport
    animateScroll.scrollMore(yDistance);

    switch (id) {
      case 'sustainability':
        logEvent(analytics, "tab_selected_sustainability");
        break;
      case 'locations':
        logEvent(analytics, "tab_selected_locations");
        break;
      case 'products':
        logEvent(analytics, "tab_selected_products");
        break;
    }
  }

  // eslint-disable-next-line
  const handleFranchiseButton = () => {
    logEvent(analytics, "tab_selected_franchise");
    history.push('/franchising');
  }

  return (
    <nav className={navClassName}>
      <button type="button" className="option" onClick={() => handleMenuButton('sustainability')}>
        <span>Sustainability</span>
      </button>
      <button type="button" className="option" onClick={() => handleMenuButton('locations')}>
        <span>Stores</span>
      </button>
      <button type="button" className="option" onClick={() => handleMenuButton('products')}>
        <span>Products</span>
      </button>
      <button type="button" className="option italic" onClick={handleFranchiseButton}>
        <span>Franchise</span>
      </button>
    </nav>
  );
}
