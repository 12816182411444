import React from 'react';
import './meditation.scss';
import ReactAudioPlayer from 'react-audio-player';
import { getAnalytics, logEvent } from 'firebase/analytics';
import HeaderBar from '../../shared/HeaderBar';
import Footer from '../../shared/Footer';
import { Helmet } from 'react-helmet';

const Meditation = () => {

  const analytics = getAnalytics();
  logEvent(analytics, 'sleepy_time_meditation_loaded');
  return <div className='Meditation'>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Sweetspot Weed Dispensary: Guided Meditation</title>
        <link rel="canonical" href="https://sweetspotfarms.com/meditation"/>
    </Helmet>
    <HeaderBar theme='light' hideNav storeNavButton />
    <div className='image-container'>

      <div className='audio-player'>
        <h1>Guided Meditation</h1>
        <ReactAudioPlayer
          src={require('../../assets/audio/sleepy-time-recording.m4a')}
          autoPlay
          controls
        />
      </div>
    </div>
    <Footer hideNav/>
  </div>
}

Meditation.propTypes = {};

Meditation.defaultProps = {};

export default Meditation;
