import React from 'react';

import ChevronDownGreen from '../../../assets/images/chevron-down-green.png';

import { Collapse } from 'antd';
import { franchiseFAQ } from '../../../helpers/data';

const { Panel } = Collapse;

const FAQChevron = (props: { panelProps: any; }) => {
  const { panelProps } = props;
  const { isActive } = panelProps;
  const iconClassName = `panelIcon${isActive ? ' rotated' : ''}`
  return (
    <img 
      src={ChevronDownGreen}
      alt="Chevron down green"
      className={iconClassName}
    />
  );
}

const FAQTitle = (props: { text: any; }) => {
  const { text } = props;
  return (
    <span className="title">
      {text}
    </span>
  );
};

export default function FAQ() {
  const questions = franchiseFAQ.map((faqItem) => {
    const { id, question, answer } = faqItem;
    return (
      <Panel
        key={id}
        header={<FAQTitle text={question} />}
        className="faqItem"
      >
        <div className="answer">
          <p>{answer}</p>
        </div>
      </Panel>
    );
  });

  return (
    <section className="FranchiseFAQ">
      <div className="contentWrapper">
        <div className="content">
          <Collapse 
            ghost
            accordion
            expandIcon={(panelProps) => <FAQChevron panelProps={panelProps} />}
            bordered={false}
          >
            {questions}
          </Collapse>
        </div>
      </div>
    </section>
  );
}
