import React from 'react';
import LogoSpinner from './LogoSpinner';

const AnimatedButton = (props: any) => {
  const {type, label, handler, theme, processing, className} = props;

  const buttonType = type ?? 'button';
  const buttonText = label ?? 'Button';
  const buttonTheme = theme ?? 'light';
  const handleButton = handler ? handler : () => console.log('Clicked!');
  const additionalClass = className ? ` ${className}` : '';

  return (
    <div className={`animatedButton${theme === 'dark' ? ' dark' : ''}${processing ? ' active' : ''}${additionalClass}`}>
      <span className="layer1" />
      <span className="layer2" />
      <button 
        type={buttonType}
        className="layer3"
        onClick={buttonType === 'submit' ? undefined : handleButton}
      >
        { processing ? 
          (
            <span className="logoSpinnerContainer">
              <LogoSpinner theme={buttonTheme} />
            </span>
          )
        :
          buttonText
        }
      </button>
    </div>
  );
};

export default AnimatedButton;
