// Import quiz data

import { quizData } from "./quiz";

// Determine distance between two latlng points in meters
// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export const getDistanceFromLatLng = (latlng1: any, latlng2: any) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(latlng2.lat-latlng1.lat);  // deg2rad below
  var dLon = deg2rad(latlng2.lng-latlng1.lng); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(latlng1.lat)) * Math.cos(deg2rad(latlng2.lat)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c * 1000; // Distance in meters
  return d;
}

function deg2rad(deg: any) {
  return deg * (Math.PI/180)
}

// Build HTML from quiz results
export const buildResultContent = (quizResults: any) => {
  let results = "";
  const totalQuestions = quizResults.length;
  quizResults.forEach((item: any, index: any) => {
    let answers = "";
    if (item.answers.length > 1) {
      answers = "</p>"
      item.answers.forEach((answer: any) => {
        answers = answers + `<li>${quizData[index].answers[answer]}</li>`;
      });
      answers = `<ul>${answers}</ul>`;
    } else if (item.answers.length === 1) {
      answers = ` ${quizData[index].answers[item.answers[0]]}</p>`;
    }
    let htmlContent = `<h3>Question ${index + 1}/${totalQuestions}</h3><p><b>Question:</b> ${item.question}</p><p><b>Answer${(item.answers.length > 1) ? "s" : ""}:</b>${answers ? answers : " <i>No answer given</i></p>"}`;
    results = results + htmlContent;
  });
  return results;
}

/**
 * Parse phone
 * Takes in a string and returns an object with a numerical and formatted phone
 * @param {string} phone
 * @returns {Phone}
 */
export const parsePhone = (phone: any) => {
  // Filter string to just numbers
  let number = phone.replace(/\D/g, '');
  if (number.length > 10) {
    number = number.substring(0, 10);
  }
  // Build formatted phone
  let formatted = '';
  if (number.length <= 3) {
    formatted = number;
  } else if (number.length > 3 && number.length <= 6) {
    formatted = `(${number.substring(0, 3)}) ${number.substring(3)}`;
  } else if (number.length > 6) {
    formatted = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
  }
  return {
    number,
    formatted
  }
}
