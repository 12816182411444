import React, { useEffect, useState } from 'react';

// Import React PDF
// https://www.npmjs.com/package/react-pdf
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'; // For Webpack 5

// Import React Router
// https://reactrouter.com/
import {
  useLocation
} from "react-router-dom";

// Import Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowRight,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

// Import documents
import hipaa from '../../assets/documents/hipaa-statement.pdf';
import termsOfUse from '../../assets/documents/terms-of-use.pdf';
import privacyPolicy from '../../assets/documents/privacy-policy.pdf';
import accessibility from '../../assets/documents/accessibility-statement.pdf';
import legalDisclaimers from '../../assets/documents/legal-disclaimers.pdf';
import refundPolicy from '../../assets/documents/refund-policy.pdf';
import cannabisSafetyInformation from '../../assets/documents/cannabis-safety-information.pdf';
import HeaderBar from '../../shared/HeaderBar';
import Footer from '../../shared/Footer';
import { getAnalytics, logEvent } from 'firebase/analytics'

const Legal = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [document, setDocument] = useState({
    loading: true,
    success: false,
    numPages: null,
    currentPage: 1,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    // Add handlers upon mount
    window.addEventListener('resize', handleResize, true);

    // Remove listeners upon unmount
    return (() => {
      window.removeEventListener('resize', handleResize, true);
    });
  }, []);

  const analytics = getAnalytics();
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setDocument((prevState) => ({
      ...prevState,
      loading: false,
      success: !!numPages, // We assume an error if numPages returns null
      numPages,
    }));
    logEvent(analytics, `legal_document_loaded_${pathname.slice(1)}`);
  }

  const handleBackButton = () => {
    if (document.currentPage > 1) {
      setDocument((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1
      }));
    }
  }

  const handleNextButton = () => {
    if (document.currentPage < document.numPages!) {
      setDocument((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1
      }));
    }
  }

  // Acquire current location
  const location = useLocation();
  const pathname = location.pathname;

  // Determine document to display
  let docRef;
  let title;
  switch (pathname) {
    case '/hipaa':
      docRef = hipaa;
      title = 'HIPAA Statement';
      break;
    case '/terms-use':
      docRef = termsOfUse;
      title = 'Terms of Use'
      break; 
    case '/privacy-policy':
      docRef = privacyPolicy;
      title = 'Privacy Policy';
      break;
    case '/accessibility':
      docRef = accessibility;
      title = 'Accessibility Statement';
      break;
    case '/legal-disclaimers':
      docRef = legalDisclaimers;
      title = 'Legal Disclaimers';
      break;
    case '/refund-policy':
      docRef = refundPolicy;
      title = 'Refund Policy';
      break;
    case '/cannabis-safety-information':
      docRef = cannabisSafetyInformation;
      title = 'Cannabis Safety Information'
      break;
    default:
      docRef = '';
  }

  // Build UI
  const pagewidth = (windowDimensions.width < 900 ? windowDimensions.width : 900);

  let isBackDisabled = true;
  let isNextDisabled = true;
  if (document.success && document.currentPage > 1) {
    isBackDisabled = false;
  }
  if (document.success && document.currentPage < document.numPages!) {
    isNextDisabled = false;
  }

  const leftControlStyle = `control left${isBackDisabled ? ' disabled' : ''}`;
  const rightControlStyle = `control right${isNextDisabled ? ' disabled' : ''}`;

  const controls = (
    <div className="controls">
      <div className={leftControlStyle}>
        <button type="button" onClick={handleBackButton}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      <div className="title">
        <h1>
          {title}
        </h1>
        { document.success && (
          <div className="pageCount">
            Page {document.currentPage} of {document.numPages}
          </div>
        )}
      </div>
      <div className={rightControlStyle}>
        <button type="button" onClick={handleNextButton}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );

  return (
    <div className="Legal">
      <HeaderBar theme="light" hideNav />
      <div className="documentContainer">
        { controls }
        <div className="document">
          <Document file={docRef} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={document.currentPage} width={pagewidth} />
          </Document>
        </div>
      </div>
      <Footer hideNav />
    </div>
  );
};

export default Legal;