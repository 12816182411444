import React from 'react';

import PortlandSplash from '../assets/images/portland-splash.png';
import VoorheesSplash from '../assets/images/voorhees-splash.png';
import OlneySplash from '../assets/images/olney-splash.png';
import ExeterSplash from '../assets/images/exeter-splash.png';
import JarsImage from '../assets/images/jars-on-shelves.png';
import DeliveryTruckAlt from '../assets/images/delivery-truck-alt.png';
import PercentileShapeAlt from '../assets/images/percentile-shape-alt.png';

import HeaderBar from './HeaderBar';
import AnimatedButton from './AnimatedButton';

import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SubscribeForm from './SubscribeForm';
import Footer from './Footer';

export default function StoreInfo(props) {
  const history = useHistory();

  const { info } = props;

  const { id, name, menus, dispensaryTypes, address, phone, email, googleLink, hours } = info;

  const isRhodeIsland = id.startsWith('ri');

  let splashImage;
  switch (name) {
    case 'Portland, ME':
      splashImage = PortlandSplash;
      break;
    case 'Voorhees, NJ':
      splashImage = VoorheesSplash;
      break;
    case 'Olney, MD':
      splashImage = OlneySplash;
      break;
    case 'Exeter, RI':
      splashImage = ExeterSplash;
      break;
    default:
      splashImage = '';
  }

  const backgroundStyle = {
    backgroundImage: `url('${splashImage}')`,
  };

  const handleDirectionsButton = () => {
    window.location.href = googleLink;
  };

  let dispensaryTypeText = '';
  dispensaryTypes.forEach((type, index) => {
    const newText = index > 0 ? ` + ${type}` : type;
    dispensaryTypeText = dispensaryTypeText + newText;
  });
  dispensaryTypeText = dispensaryTypeText + ' cannabis';

  const tel = `+1${phone.replaceAll('.', '')}`;

  const hoursContent = hours.map((data, i) => {
    let titleText = '';
    if (hours.length === 1) {
      titleText = 'hours';
    } else {
      const {dispensaryTypes} = data;
      if (dispensaryTypes.length === 1) {
        titleText = `${dispensaryTypes[0]} hours only`;
      } else {
        dispensaryTypes.forEach((type, index) => {
          const newText = index > 0 ? ` and ${type}` : type;
          titleText = titleText + newText;
        });
      }
    }

    const {daily} = data;
    const dailyTimes = daily.map((operation) => {
      const {day, description} = operation;
      const label = day.substring(0, 3);
      return (
        <span className="item" key={day}>
          <span className="label">
            {label}:
          </span>
          <span className="text">
            {description}
          </span>
        </span>
      );
    });

    if(!dailyTimes.length) { 
      return (
        <div className="hours" key={i}>
          <span className="title">
            {titleText}
          </span> 
          <span className="label">
             Coming Soon
          </span>
        </div>
      );
    } else { 
      return (
        <div className="hours" key={i}>
          <span className="title">
            {titleText}
          </span> 
          <span className="description">
            {dailyTimes}
          </span>
        </div>
      );
    }

  });

  const menuButtons = menus.map((menu) => {
    const {dispensaryType} = menu;
    const menuId = menu.id;
    const label = `${dispensaryType} menu`;
    const className = `${dispensaryType}MenuButton`;
    const menuUrl = `/store/${id}/menu/${dispensaryType.substring(0, 3)}`;
    const handler = () => {
      if (!menuId.length) { 
        console.log("No menu link provided.")
      } else { 
        history.push(menuUrl);
      }
    }
    return (
      <div key={menuId} className="buttonContainer">
        <AnimatedButton
          label={label}
          handler={handler}
          theme="light"
          className={className}
        />
      </div>
    )
  });

  const handleDeliverySignupButton = () => {
    history.push({
      pathname: '/rhodeisland/delivery',
      state: { internalReferral: true },
    })
  };

  const menusStyle = {} as any;
  if (isRhodeIsland) {
    menusStyle.borderBottom = 'none';
  }

  return (
    <main className="StoreInfo">
      <div className="BannerBackground" style={backgroundStyle} />
      <div className="Banner">
        <HeaderBar
          theme="light" 
          hideNav
          storeNavButton
          storeNavButtonText="Shop By Location"
        />
        <div className="contentWrapper">
          <div className="content">
            <h1>
              {name}
            </h1>
          </div>
        </div>
      </div>
      {/* Store Details */}
      <section className="Details">
        <div className="contentWrapper">
          <div className="content">
            <div className="dispensaryType">
              <span className="title">
                {dispensaryTypeText}
              </span>
            </div>
            <div className="locationInfo">
              <div className="addressAndContact">
                <div className="addressContainer">
                  <div className="address">
                    <span className="title">
                      Address
                    </span>
                    <span className="description">
                      <span className="item">
                        {address.street}
                      </span>
                      <span className="item">
                        {address.city}, {address.state} {address.zip}
                      </span>
                    </span>
                  </div>
                  <div className="directions">
                    <AnimatedButton
                      label="Directions"
                      theme="dark"
                      handler={handleDirectionsButton}
                      className="getDirections"
                    />
                  </div>
                </div>
                <div className="contact">
                  <span className="title">
                    Contact
                  </span>
                  <span className="description">
                    <span className="item">
                      <span className="label">
                        Telephone:
                      </span>
                      <span className="text">
                        <a href={`tel:${tel}`}>
                          {phone}
                        </a>
                      </span>
                    </span>
                    <span className="item">
                      <span className="label">
                        Email:
                      </span>
                      <span className="text">
                        <a href={`mailto:${email}`}>
                          {email}
                        </a>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="hoursContainer">
                {hoursContent}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Store Menus */}
      <section className="Menus" style={menusStyle}>
        <div className="contentWrapper">
          <div className="content">
            <div className="imageContainer">
              <img src={JarsImage} alt="Labeled jars of cannabis on wooden shelves" />
            </div>
            <div className="menusContainer">
              <p className="heading">
                Check out our menu and daily deals.
              </p>
              <p className="subHeading">
                Preorder online to save time.
              </p>
              <div className="menuButtons">
                {menuButtons}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer hideNav />
    </main>
  );
}
