import React from 'react';

import Step1Image from '../../../assets/images/find-your-sweetspot-step-1.png';
import Step2Image from '../../../assets/images/find-your-sweetspot-step-2.png';
import Step3Image from '../../../assets/images/find-your-sweetspot-step-3.png';

const marqueeText = (
  <>
    <span className="text">Find your sweetspot in 3 easy steps</span>
    <span className="circle">&#9679;</span>
  </>
);

const marqueeTextRepeats = 8;

const marqueeContent: any[] = [];

for (let i = 0; i < marqueeTextRepeats; i++) {
  marqueeContent.push(
    <React.Fragment key={i}>
      {marqueeText}
    </React.Fragment>
  );
}

export default function GetStarted() {
  return (
    <section className="GetStarted">
      <div className="contentWrapper">
        <div className="marquee">
          <span className="content">
            {marqueeContent}
          </span>
        </div>
        <div className="stepsWrapper">
          <div className="step">
            <div className="sideImage">
              <img src={Step1Image} alt="Styled graphic of a paper with dots and lines" />
            </div>
            <div className="content">
              <p className="heading">
                <span className="number">
                  Step 1<span className="colon">:</span>
                </span>
                <span className="title">
                  Take the Intro Quiz
                </span>
              </p>
              <div className="contentImage">
                <img src={Step1Image} alt="Styled graphic of a paper with dots and lines" />
              </div>
              <p className="description">
                Answer 6 questions to identify products that fit your needs.
              </p>
            </div>
          </div>
          <div className="step middle">
            <div className="sideImage">
              <img src={Step2Image} alt="Styled graphic of a box with the Sweetspot logo and several long vertical items inside" />
            </div>
            <div className="content">
              <p className="heading">
                <span className="number">
                  Step 2<span className="colon">:</span>
                </span>
                <span className="title">
                  Get a Starter Kit*
                </span>
              </p>
              <div className="contentImage">
                <img src={Step2Image} alt="Styled graphic of a box with the Sweetspot logo and several long vertical items inside" />
              </div>
              <p className="description">
                Try 4 low-dose samples of different strains and track your results.
              </p>
              <p className="disclaimer">
                * only available in select states
              </p>
            </div>
          </div>
          <div className="step fullBottomBorder">
            <div className="sideImage">
              <img src={Step3Image} alt="Styled graphic of a cannabis leaf on top of a circular shape" />
            </div>
            <div className="content">
              <p className="heading">
                <span className="number">
                  Step 3<span className="colon">:</span>
                </span>
                <span className="title">
                  Speak to a Specialist
                </span>
              </p>
              <div className="contentImage">
                <img src={Step3Image} alt="Styled graphic of a cannabis leaf on top of a circular shape" />
              </div>
              <p className="description">
                Work with a Sweetspot guide during your first shopping experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
