import React, { useEffect, useState } from 'react';

// Import React Router
// https://reactrouter.com/
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// Import middleware
import { processQuizData } from '../../services/forms';
import { getAnalytics, logEvent } from "firebase/analytics";

// Import quiz data
import { determineQuizResults, prepareQuizResponses, quizData2, quizTransitionDelay } from '../../helpers/quiz';

// Import components
import Header from './sections/Header';
import Question from './sections/Question';
import Complete from './sections/Complete';
import Results from './results/Results';
import { Helmet } from 'react-helmet';

export default function Quiz() {
  const analytics = getAnalytics();
  // Set initial state
  const [questionIndex, setQuestionIndex] = useState(0); // Index 0 is question #1; use -1 for intro screen
  const [isComplete, setIsComplete] = useState(false);
  const [quizResponses, setQuizResponses] = useState({
    multipleChoice: [],
    activity: "",
    experience: "",
  });
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: "",
    age: "",
    gender: "",
  });
  const [transition, setTransition] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [quizResults, setQuizResults] = useState([]);

  // On mount, build multiple choice data based on quiz data
  // Fade in quiz content when complete
  useEffect(() => {
    const choiceData = [];
    quizData2.forEach((item) => {
      if (item.type === "multipleChoice") {
        choiceData.push({
          ...item,
          answers: [],
        });
      }
    });
    setQuizResponses((prevState) => ({
      ...prevState,
      multipleChoice: choiceData
    }));
    setTimeout(() => {
      setTransition(false);
    }, quizTransitionDelay);
  }, []);

  const resetQuizData = () => {
    // Rebuild quiz data
    const choiceData = [];
    quizData2.forEach((item) => {
      if (item.type === "multipleChoice") {
        choiceData.push({
          ...item,
          answers: [],
        });
      }
    });
    setQuizResponses({
      multipleChoice: choiceData,
      activity: "",
      experience: "",
    });
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      location: "",
      age: "",
      gender: "",
    });
  }

  // Determine total number of questions
  const totalNumberOfQuestions = quizData2.length;

  // Acquire history
  const history = useHistory();

  // Build handler for close buttons
  const handleQuizClose = () => {
    if (window.confirm("Your quiz is not complete.  Are you sure you wish to leave?")) {
      logEvent(analytics,`quiz_closed_early_question=${questionIndex + 1}`);
      history.push("/");
    }
  }

  const handleResultsClose = () => {
    logEvent(analytics,`quiz_results_closed`);
    history.push("/");
  }

  // Build callback for updating question index value
  // Takes a string argument of "increment" | "decrement"
  const updateQuestionIndex = (action) => {
    setTransition(true);
    setTimeout(() => {
      if (action === "increment") {
        setQuestionIndex((prevState) => (prevState + 1));
      }
      if (action === "decrement") {
        setQuestionIndex((prevState) => (prevState - 1));
      }
      setTransition(false);
    }, quizTransitionDelay);
  }

  // If the question index has reached the total number of questions,
  // show the completion screen
  useEffect(() => {
    if (questionIndex === totalNumberOfQuestions) {
      setIsComplete(true);
    } else if ((questionIndex !== totalNumberOfQuestions) && isComplete) {
      setIsComplete(false);
    }
  }, [questionIndex, isComplete, totalNumberOfQuestions]);

  // Build callback for updating multiple choice answers
  // Takes a question index and array of answers as arguments
  const updateMultipleChoice = (index, answers) => {
    let choicesUpdate = [
      ...quizResponses.multipleChoice,
    ];
    choicesUpdate[index].answers = answers;
    setQuizResponses((prevState) => ({
      ...prevState,
      multipleChoice: choicesUpdate,
    }));
  }

  // Build callback for updating open text responses
  // Takes a key and value (strings) as arguments
  const updateQuizResponses = (key, value) => {
    setQuizResponses((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Build callback for updating user data
  // Takes a key and value (strings) as arguments
  const updateUserData = (key, value) => {
    setUserData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  // Build callback for completing quiz
  const completeQuiz = (token) => {
    // Build quiz results
    const results = determineQuizResults(quizResponses.multipleChoice);

    // Build callbacks for sendQuizResults
    const success = () => {
      console.log("Form submission successful.");
      console.log("Building results...");
      setQuizResults(results);
      setProcessing(false);
      history.push("/quiz/results");
      resetQuizData();
    }

    const failure = () => {
      setProcessing(false);
      window.alert("An error occurred when processing your quiz results.  Please try again later.");
    }

    // Build quiz responses
    const responses = prepareQuizResponses(quizResponses);

    // Set processing to true
    setProcessing(true);
    
    // Fire middleware
    processQuizData({
      token,
      userData: {
        email: userData.email.trim(),
        firstName: userData.firstName.trim(),
        lastName: userData.lastName.trim(),
        location: userData.location,
        age: userData.age,
        gender: userData.gender,
      },
      responses,
      results,
      success, 
      failure
    });
  }

  // Acquire route match data
  const match = useRouteMatch();

  // Determine theme
  const location = useLocation();
  const theme = location.pathname.includes("results") ? "dark" : "light";
  const quizStyle = `Quiz ${theme}`;

  return (
    <div className={quizStyle}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Sweetspot Weed Dispensary: Intro Quiz</title>
          <link rel="canonical" href="https://sweetspotfarms.com/quiz"/>
      </Helmet>
      <Switch>
        <Route path={`${match.url}/results`}>
          <Header theme={theme} handleClose={handleResultsClose} />
          <main className="quizViewContainer">
            <div className="contentContainer">
              <div className={`content ${theme}`}>
                <Results quizResults={quizResults} />
              </div>
            </div>
          </main>
        </Route>
        <Route exact path={`${match.url}`}>
          <Header theme={theme} handleClose={handleQuizClose} />
          <main className="quizViewContainer">
            <div className="contentContainer">
              <div className={`content ${theme}`}>
                {(!isComplete && (questionIndex >= 0)) && ( 
                  <Question 
                    totalQuestions={totalNumberOfQuestions}
                    questionIndex={questionIndex}
                    quizResponses={quizResponses}
                    updateQuestionIndex={updateQuestionIndex}
                    updateMultipleChoice={updateMultipleChoice}
                    updateQuizResponses={updateQuizResponses}
                    transition={transition}
                  />
                )}
                {(isComplete) && ( 
                  <Complete 
                    userData={userData}
                    updateQuestionIndex={updateQuestionIndex}
                    updateUserData={updateUserData}
                    completeQuiz={completeQuiz}
                    transition={transition}
                    processing={processing}
                  />
                )}
              </div>
            </div>
          </main>
        </Route>
        <Route path="*">
          <Redirect to={`${match.url}`} />
        </Route>
      </Switch>
    </div>
  );
}
