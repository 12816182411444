import React from 'react';
import { animateScroll } from 'react-scroll';

// Import React Router
// https://reactrouter.com/
import {
  Link
} from "react-router-dom";

// Import Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer(props: any) {
  const {hideNav} = props;

  const handleFooterButton = (id: any) => {
    const section: any = document.getElementById(id);
    const rect = section.getBoundingClientRect();
    const yDistance = rect.top; // From the top of the viewport
    animateScroll.scrollMore(yDistance);
  }

  return (
    <footer className="Footer">
      <div className="contentWrapper">
        <div className="content">
          <div className="connect">
            <span className="heading">
              Follow Us
            </span>
            <span className="social">
              <a href="https://www.instagram.com/sweetspotfarms/">
                <span className="icon instagram">
                  <FontAwesomeIcon icon={faInstagram} />
                </span>
              </a>
            </span>
            <span className="email">
              <a href="mailto: contact@sweetspotfarms.com">
                contact@sweetspotfarms.com
              </a>
            </span>
          </div>
          <div className="navigation">
            <div className="legal">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-use">Terms of Use</Link>
              <Link to="/refund-policy">Refund Policy</Link>
              <Link to="/legal-disclaimers">Legal Disclaimers</Link>
              <Link to="/accessibility">Accessibility Statement</Link>
              <Link to="/hipaa">HIPAA Statement</Link>
              <Link to="/cannabis-safety-information">Cannabis Safety Information</Link>
            </div>
            <div className="pages">
              {!hideNav && (
                <>
                  <button type="button" className="option" onClick={() => handleFooterButton('sustainability')}>
                    Sustainability
                  </button>
                  <button type="button" className="option" onClick={() => handleFooterButton('locations')}>
                    Stores
                  </button>
                  <button type="button" className="option" onClick={() => handleFooterButton('products')}>
                    Products
                  </button>
                  <Link to="/franchising">
                    Franchise
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer> 
  );
}
